<template>
  <div>
    <div
      class="modal fade"
      id="modalDone"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalDoneTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Daily não preenchida no dia de hoje
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center mt-3">
              <div class="col-md-12">
                <label>O que fez ontem? </label>
                <input
                  v-model="daily.yesterday"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row justify-content-center mt-3">
              <div class="col-md-12">
                <label>O que vai fazer hoje?</label>
                <input v-model="daily.today" type="text" class="form-control" />
              </div>
            </div>
            <div class="row justify-content-center mt-3">
              <div class="col-md-12">
                <label>Houve algum impedimento?</label>
                <select
                  class="form-control"
                  v-model="status"
                  :disabled="id ? 'true' : false"
                >
                  <option value="">Não</option>
                  <option value="1">Sim</option>
                </select>
              </div>
            </div>
            <div class="row justify-content-center mt-3">
              <div class="col-md-12" v-if="status">
                <label for="">Descreva-o por favor</label>
                <input
                  v-model="daily.impediment"
                  id="impedido"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="saveDaily"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center flex-column">
      <!-- Área de criação de  com CKEditor -->
      <div class="col-md-12">
        <div class="card">
          <div
            v-if="showPostForm"
            class="d-flex align-items-center flex-column"
          >
            <!-- Área de criação de  com CKEditor -->
            <div class="col-md-12">
              <div class="card">
                <div class="row d-flex align-items-center flex-column">
                  <div class="col-md-11">
                    <div class="card-title mt-1">Novo Post:</div>
                    <ckeditor
                      v-model="text"
                      :editor="editor"
                      ref="editorInstance"
                    />
                    <div
                      class="row d-flex align-items-center justify-content-end mt-1 px-1"
                    >
                      <label for="images" class="btn btn-add btn-crud p-0 mr-1">
                        <i
                          class="fas fa-camera d-flex align-items-center justify-content-center"
                          style="height: 100%"
                        ></i>
                        <input
                          type="file"
                          id="images"
                          class="d-none"
                          @change="uploadImage"
                          ref="fileInput"
                          multiple
                        />
                      </label>
                      <button class="btn btn-primary btn-sm" @click="newPost()">
                        Postar
                      </button>
                    </div>
                    <hr v-if="previewImage" />
                    <div class="card-title" v-if="previewImage">
                      Imagem do Post:
                    </div>
                    <div v-if="previewImages.length > 0" class="carousel-container">
                      <!-- Botão para imagem anterior -->
                      <button
                        v-if="previewImages.length > 1"
                        class="carousel-btn prev-btn"
                        @click="prevPreviewImage"
                      >
                        <i class="fas fa-chevron-left"></i>
                      </button>

                      <!-- Exibição da imagem atual com botão para excluir -->
                      <div class="carousel-image" style="position: relative;">
                        <img
                          :src="previewImages[currentPreviewIndex]"
                          class="photo"
                          alt="Preview"
                        />
                        <!-- Botão X para excluir a imagem -->
                        <button
                          class="btn btn-danger"
                          style="
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            border-radius: 50%;
                            padding: 5px 10px;
                            font-size: 16px;
                            cursor: pointer;
                          "
                          @click="removePreviewImage(currentPreviewIndex)"
                        >
                          &times;
                        </button>
                      </div>

                      <!-- Botão para próxima imagem -->
                      <button
                        v-if="previewImages.length > 1"
                        class="carousel-btn next-btn"
                        @click="nextPreviewImage"
                      >
                        <i class="fas fa-chevron-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Exibição de posts existentes -->
      <div class="col-md-12" v-if="posts.length > 0">
        <!-- Aqui entra o conteúdo dos posts existentes -->
      </div>

      <!-- Alerta quando não existem posts -->
      <div class="col-md-12" v-else>
        <div
          class="alert alert-warning"
          role="alert"
          style="font-size: 1.2em; padding: 15px; text-align: center"
        >
          <p>
            <i class="fas fa-exclamation-triangle"></i> Não existem posts no
            momento.
          </p>
        </div>
      </div>

      <!-- Área de moderação de posts -->
      <div class="col-md-12" v-if="approves.length && $store.state.user.mod">
        <button
          class="w-100 btn btn-primary"
          type="button"
          data-toggle="collapse"
          data-target="#moderar"
          aria-expanded="false"
          aria-controls="moderar"
        >
          Posts aguardando aprovação ({{ approves.length }})
        </button>
        <div
          class="collapse"
          id="moderar"
          style="border-bottom: 1px solid black"
        >
          <div
            v-for="(approve, index) in approves"
            :key="index"
            class="card p-1"
            :style="
              'border-bottom: 1px solid ' +
              approve.primary_color +
              '; border-top: 1px solid ' +
              approve.primary_color +
              '; border-right: ' +
              (approve.id % 2 ? 1 : 8) +
              'px solid ' +
              approve.primary_color +
              '; border-left: ' +
              (approve.id % 2 ? 8 : 1) +
              'px solid ' +
              approve.primary_color
            "
          >
            <div class="row p-0">
              <div
                :style="
                  'border-bottom: ' +
                  approve.secondary_color +
                  ' 1px solid; padding-bottom: 8px;'
                "
                class="col-12 d-flex align-items-center justify-content-start"
              >
                <b-avatar
                  size="50"
                  variant="light-primary"
                  :src="approve.user.photo != false ? approve.user.photo : '.'"
                  class="badge-minimal"
                />
                <b class="px-1" style="font-size: 3vh; line-height: 2.2vh"
                  >{{ approve.user.name }}<br /><span style="font-size: 2vh">{{
                    approve.created_at_f
                  }}</span></b
                >
              </div>
            </div>
            <div class="d-flex p-1 flex-column" v-html="approve.text"></div>
            <div class="d-flex align-items-center justify-content-start">
              <div
                v-for="(image, imageIndex) in approve.images"
                :key="imageIndex"
                class="ml-1 mb-1"
                :style="
                  'background-repeat: no-repeat; background-size: cover; background-position: center; cursor: pointer; border-radius: 8px; border: solid ' +
                  approve.primary_color +
                  ' 1px; background-image: url(' +
                  image.photo +
                  '); width: 35vh; height: 35vh;'
                "
              ></div>
            </div>
            <div class="d-flex justify-content-center align-itens-center">
              <div class="col-md-6 text-center">
                <button
                  class="btn btn-outline-danger"
                  @click="setApprove(approve.id, 2)"
                >
                  <i class="fas fa-ban"></i> Rejeitar
                </button>
              </div>
              <div class="col-md-6 text-center">
                <button
                  class="btn btn-outline-success"
                  @click="setApprove(approve.id, 1)"
                >
                  <i class="fas fa-check"></i> Aprovar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button v-show="showScrollTop" @click="scrollToTop" class="btn-scroll-up">
        ↑
      </button>
      <div class="d-flex justify-content-left mb-2">
        <button
          class="btn btn-primary btn-circle"
          @click="togglePostForm"
          title="Novo Post"
        >
          {{ showPostForm ? "-" : "+" }}
        </button>
      </div>

      <div class="mt-1 col-md-12" v-for="(post, index) in posts" :key="index">
        <div
          class="card p-1"
          :style="'border-left: 5px solid rgb(21, 72, 138); border-right: 5px solid rgb(21, 72, 138);'"
        >
          <div class="row p-0">
            <div
              :style="
                'border-bottom: ' +
                post.secondary_color +
                ' 1px solid; padding-bottom: 8px;'
              "
              class="col-12 d-flex align-items-center justify-content-between"
            >
              <div class="d-flex align-items-center">
                <b-avatar
                  size="50"
                  variant="light-primary"
                  :src="post.user.photo != false ? post.user.photo : '.'"
                  class="post-avatar badge-minimal"
                />
                <b class="post-user-name px-1">
                  {{ post.user.name }}
                  <br />
                  <span class="post-user-date">{{ post.created_at_f }}</span>
                </b>
              </div>
              <button
                class="btn btn-crud btn-outline-danger"
                title="Excluir post"
                @click="deletePost(post.id)"
                v-show="$store.state.user.mod"
                style="background-color: transparent; border: 2px solid red; color: red; padding: 10px; border-radius: 5px; cursor: pointer;"
                onmouseover="this.style.backgroundColor='white'; this.style.color='red';"
                onmouseout="this.style.backgroundColor='transparent'; this.style.color='red';"
              >
                <i class="fas fa-trash"></i>
              </button>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <div
                class="modal fade"
                :id="'delete_' + post.id"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="exampleModalLabel">
                        Tem certeza que deseja deletar o post?
                      </h1>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="deletePost(post.id)"
                      >
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex p-1 flex-column justify-content-start align-items-start"
            v-html="post.text"
          ></div>

          <div class="carousel-container">
            <!-- Botão para imagem anterior -->
            <button
              v-if="post.images && post.images.length > 1"
              class="carousel-btn prev-btn"
              @click="prevImage(post.id)"
            >
              <i class="fas fa-chevron-left"></i>
            </button>

            <!-- Exibição da imagem atual -->
            <div class="carousel-image">
              <img
                v-if="post.images && post.images.length > 0"
                :src="post.images[currentImageIndex[post.id] || 0].photo"
                class="photo"
                alt="Post image"
              />
            </div>

            <!-- Botão para próxima imagem -->
            <button
              v-if="post.images && post.images.length > 1"
              class="carousel-btn next-btn"
              @click="nextImage(post.id)"
            >
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>

          <div
            class="row mt-1 px-1"
            :style="'border-bottom: solid ' + post.secondary_color + ' 1px;'"
          >
            <!-- Botão de Curtir -->
            <button
              class="btn px-1"
              :style="post.liked ? 'color: #4488aa;' : ''"
              @click="react(post.id, index)"
            >
              <i
                class="fas fa-thumbs-up"
                data-toggle="tooltip"
                data-placement="top"
                title="Curtir"
              ></i>
            </button>
            <a
              class="like-link"
              style="margin-top: 9px"
              data-toggle="modal"
              data-target="#modalPosts"
              v-if="post.likes_count !== 0"
              @click="selectPostLikes(post)"
            >
              <b
                >{{ post.likes_count }} curtida{{
                  post.likes_count > 1 ? "s" : ""
                }}</b
              >
            </a>
            <!-- Botão de Comentários -->
            <button
              class="btn pr-1 d-flex align-items-center"
              @click="getComments(post.id, index, post.show_comments)"
            >
              <i
                :class="
                  'fas ' +
                  (post.show_comments ? 'fa-comment-slash' : 'fa-comment')
                "
                data-toggle="tooltip"
                data-placement="top"
                title="Comentários"
              ></i>
              <span style="margin-left: 5px">{{
                post.comments_count || 0
              }}</span>
            </button>
          </div>

          <br />
          <br />
          <div v-show="post.show_comments">
            <div>
              <div
                style="
                  position: relative;
                  display: flex;
                  align-items: center;
                  border: 1px solid #ced4da;
                  border-radius: 0.25rem;
                  overflow: hidden;
                "
              >
                <input
                  type="text"
                  class="form-control"
                  style="flex-grow: 1; border: none; border-radius: 0"
                  v-model="comment[post.id]"
                  placeholder="Escreva seu comentário..."
                  @keyup.enter="addComment(post.id, index)"
                />
                <div
                  style="
                    position: absolute;
                    right: 50px;
                    top: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                  "
                >
                  <button
                    class="btn"
                    style="background: transparent; border: none"
                    @click="toggleEmojiPicker(post.id)"
                  >
                    <i class="fas fa-smile"></i>
                  </button>
                </div>

                <button
                  class="btn btn-primary"
                  style="
                    margin-left: -1px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                  "
                  @click="addComment(post.id, index)"
                >
                  <i class="fas fa-arrow-right"></i>
                </button>
              </div>
              <picker
                style="position: absolute; right: 0; z-index: 999"
                v-if="emojiPickerVisible[post.id]"
                @select="(emoji) => addEmoji(emoji, post.id)"
              />
            </div>
            <br /><br />
            <div
              class="d-flex align-items-center justify-content-start flex-row flex-wrap"
              v-if="post.comments.length"
            >
              <div
                class="px-2 py-1 w-100"
                style="border-top: 1px solid #ccc"
                v-for="(comment, commentIndex) in post.comments"
                :key="commentIndex"
              >
                <b class="my-1">
                  <b-avatar
                    size="30"
                    variant="light-primary"
                    :src="comment.user.photo ? comment.user.photo : '.'"
                    class="badge-minimal"
                  />
                  {{ comment.user.name }} - {{ comment.created_at_f }}
                  <br />
                  <hr />
                  <span style="font-weight: 500">
                    {{ comment.text }}
                  </span>
                  <br />
                </b>
                <br />
                <button
                  v-if="$store.state.user.id === comment.user.id || $store.state.user.id === post.user.id"
                  class="btn pl-0"
                  @click="deleteComment(comment.id, post.id, index)"
                >
                  <i class="fas fa-trash"></i> <b>Excluir</b>
                </button>
                <button
                  class="btn px-1"
                  :style="comment.liked ? 'color: #4488aa;' : ''"
                  @click="reactToComment(post.id, comment.id, index, commentIndex)"
                >
                  <i
                    class="fas fa-thumbs-up"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Curtir"
                  ></i>
                </button>
                <a
                  class="like-link"
                  data-toggle="modal"
                  data-target="#modalComments"
                  v-if="comment.likes_count !== 0"
                  @click="selectCommentLikes(comment)"
                >
                  <b>
                    {{ comment.likes_count }} curtida{{ comment.likes_count > 1 ? "s" : "" }}
                  </b>
                </a>

                <!-- <button class="btn px-1">
                  <i class="fas fa-comment"></i> <b>Responder</b>
                </button> -->
              </div>
            </div>
            <div class="py-1 text-center" v-else>
              <b>Não há comentários</b>
            </div>
            <div
              class="modal fade"
              id="modalComments"
              tabindex="-1"
              role="dialog"
              aria-labelledby="modalCommentsTitle"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header text-center">
                    <h5
                      class="modal-title text-center"
                      id="exampleModalLongTitle"
                    >
                      Curtidas
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-md-12">
                        <div v-if="selectedCommentLikes.length > 0">
                          <ul class="list-unstyled mt-1">
                            <li
                              v-for="like in selectedCommentLikes"
                              :key="like.id"
                            >
                              <b-avatar
                                size="40"
                                variant="light-primary"
                                :src="like.user.photo ? like.user.photo : ''"
                                class="mr-2"
                              ></b-avatar>
                              <span class="font-weight-bold">{{
                                like.user.name
                              }}</span>
                              <hr class="hr-curtidas" />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Fechar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal fade"
            id="modalPosts"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modalPostsTitle"
            aria-hidden="true"
          >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header text-center">
                <h5 class="modal-title text-center" id="exampleModalLongTitle">
                  Curtidas
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <!-- Verifica se é uma curtida de post ou de comentário -->
                    <div v-if="selectedPostLikes.length > 0">
                      <ul class="list-unstyled mt-1">
                        <li v-for="like in selectedPostLikes" :key="like.id">
                          <b-avatar
                            size="40"
                            variant="light-primary"
                            :src="like.user.photo ? like.user.photo : ''"
                            class="mr-2"
                          ></b-avatar>
                          <span class="font-weight-bold">{{
                            like.user.name
                          }}</span>
                          <hr class="hr-curtidas" />
                        </li>
                      </ul>
                    </div>
                    <div v-else-if="selectedCommentLikes.length > 0">
                      <ul class="list-unstyled mt-1">
                        <li v-for="like in selectedCommentLikes" :key="like.id">
                          <b-avatar
                            size="40"
                            variant="light-primary"
                            :src="like.user.photo ? like.user.photo : ''"
                            class="mr-2"
                          ></b-avatar>
                          <span class="font-weight-bold">{{
                            like.user.name
                          }}</span>
                          <hr class="hr-curtidas" />
                        </li>
                      </ul>
                    </div>
                    <div v-else>
                      <p class="text-center">Nenhuma curtida encontrada.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-personalizada">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCardText, BLink } from "bootstrap-vue";
import { BCard, BCardBody, BAvatar } from "bootstrap-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Picker } from "emoji-mart-vue";

export default {
  data() {
    return {
      currentImageIndex: {},
      currentPostIndex: 0,
      total: 0,
      page: 1,
      showScrollTop: false,
      daily: {},
      status: "",
      id: 0,
      showPostForm: false,
      done_dailies: "",
      selectedCommentLikes: [],
      selectedPostLikes: [],
      text: "",
      posts: [],
      approves: [],
      comment: [],
      emojiPickerVisible: {},
      editor: ClassicEditor,
      editorConfig: {
        width: "200px",
        height: "200px",
      },
      previewImages: [], // Altera de `previewImage` para armazenar várias imagens
      currentPreviewIndex: 0, // Controle de índice para o carousel
      post_id: null,
      loadingPosts: false,
    };
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BCard,
    BCardBody,
    BAvatar,
    Picker,
  },
  methods: {

    uploadImage(e) {
      const files = e.target.files;
      this.previewImages = []; // Limpa as imagens anteriores do preview
      this.originalFiles = Array.from(files); // Salva os arquivos originais

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(files[i]);

        reader.onload = (event) => {
          this.previewImages.push(event.target.result);
        };
      }
    },

    prevPreviewImage() {
      this.currentPreviewIndex =
        (this.currentPreviewIndex - 1 + this.previewImages.length) %
        this.previewImages.length;
    },

    nextPreviewImage() {
      this.currentPreviewIndex =
        (this.currentPreviewIndex + 1) % this.previewImages.length;
    },

    removePreviewImage(index) {
      if (index >= 0 && index < this.previewImages.length) {
        // Remove a imagem do preview
        this.previewImages.splice(index, 1);

        // Cria um novo conjunto de arquivos mantendo a ordem restante
        const dataTransfer = new DataTransfer();
        const files = Array.from(this.fileInput.files); // Converte o FileList para Array

        files.forEach((file, fileIndex) => {
          if (fileIndex !== index) {
            dataTransfer.items.add(file); // Adiciona apenas os arquivos que permanecem
          }
        });

        // Atualiza o input com os arquivos restantes e mantém a ordem
        this.fileInput.files = dataTransfer.files;

        // Ajusta o índice atual do carousel
        if (this.currentPreviewIndex >= this.previewImages.length) {
          this.currentPreviewIndex = Math.max(0, this.previewImages.length - 1);
        }
      } else {
        console.warn("Índice inválido ao tentar remover imagem.");
      }
    },

    selectCommentLikes(comment) {
      // Atualiza o array com os likes do comentário selecionado
      this.selectedCommentLikes = comment.comment_likes || [];
    },
    selectPostLikes(post) {
      // Atualiza o array com os likes do post selecionado
      this.selectedPostLikes = post.likes || [];
    },

    nextImage(postId) {
      if (!this.currentImageIndex[postId]) {
        this.$set(this.currentImageIndex, postId, 0);
      }

      const post = this.posts.find((post) => post.id === postId);
      if (post && post.images && post.images.length > 0) {
        const index = this.currentImageIndex[postId];
        this.currentImageIndex[postId] = (index + 1) % post.images.length; // Avança para a próxima imagem ou volta ao início
      }
    },
    prevImage(postId) {
      if (!this.currentImageIndex[postId]) {
        this.$set(this.currentImageIndex, postId, 0);
      }

      const post = this.posts.find((post) => post.id === postId);
      if (post && post.images && post.images.length > 0) {
        const index = this.currentImageIndex[postId];
        this.currentImageIndex[postId] =
          (index - 1 + post.images.length) % post.images.length; // Volta para a imagem anterior ou para a última
      }
    },

    scrollToTop() {
      const scrollStep = -window.scrollY / 10;
      const interval = setInterval(() => {
        if (window.scrollY !== 0) {
          window.scrollBy(0, scrollStep);
        } else {
          clearInterval(interval);
        }
      }, 15);
    },

    handleScroll() {
      this.showScrollTop = window.scrollY > 1000;

      const bottomOfWindow =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 100;

      if (bottomOfWindow && !this.loadingPosts) {
        this.getPosts(this.currentPage + 1);
      }
    },

    togglePostForm() {
      this.showPostForm = !this.showPostForm;
    },

    getNotifications() {
      const api = this.$store.state.api + "notifications_user";

      axios
        .get(api)
        .then((response) => {
          this.notifications = response.data;
          this.newNotificationCount = this.notifications.length;
        })
        .catch((error) => {
          this.$message(null, error.response.data, "error");
        });
    },
    getDailyDone: function () {
      const self = this;
      const api = self.$store.state.api + "done-dailies";

      var user = self.$store.state.user;

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          self.done_dailies = response.data;
          if (self.done_dailies == 1 && user.daily == 1) {
            $("#modalDone").modal("show");
          }
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },

    saveDaily: function () {
      const self = this;
      let api = self.$store.state.api + "dailies";

      self.$loading(true);

      if (self.daily.id) {
        api += "/" + self.daily.id;
      }

      if (!self.status) {
        self.daily.impediment = "Não";
      } else {
        self.daily.impediment = "Sim, " + self.daily.impediment;
      }

      axios
        .post(api, self.daily)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.$loading(false);
          this.$router.push("/dailies");
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },

    uploadImage(e) {
      const files = e.target.files;
      this.previewImages = []; // Limpa as imagens anteriores do preview
      this.fileInput = e.target; // Salva a referência ao input

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(files[i]);

        reader.onload = (event) => {
          this.previewImages.push(event.target.result);
        };
      }
    },


    getPosts: function (page = 1) {
      if (this.loadingPosts || (this.lastPage && page > this.lastPage)) {
        return; // Evita carregamentos desnecessários
      }

      this.loadingPosts = true;

      const api = this.$store.state.api + "get_posts?page=" + page;

      axios
        .post(api)
        .then((response) => {
          if (page === 1) {
            this.posts = response.data.data; // Primeira página
          } else {
            this.posts = [...this.posts, ...response.data.data]; // Adiciona à lista existente
          }

          this.total = response.data.total;
          this.currentPage = response.data.current_page;
          this.lastPage = response.data.last_page;

          response.data.data.forEach((post) => {
            if (!post.hasOwnProperty("liked")) {
              post.liked = false;
            }
            if (!post.hasOwnProperty("likes_count")) {
              post.likes_count = post.likes.length;
            }
            if (!post.hasOwnProperty("comments_count")) {
              post.comments_count = post.comments ? post.comments.length : 0;
            }
            post.likes.forEach((like) => {
              if (like.user_id === this.$store.state.user.id) {
                post.liked = true;
              }
            });
          });

          this.loadingPosts = false;
        })
        .catch((error) => {
          this.loadingPosts = false;
          this.$message(null, error.response.data, "error");
        });
    },

    //lógica do envio de comentários

    sendComment(postId, index) {
      if (this.newComment.trim() !== "") {
        this.addComment(postId, index);
        this.newComment = "";
      }
    },
    addComment(postId, index) {},

    getApproves: function () {
      const self = this;
      const api =
        self.$store.state.api +
        "posts?with[]=user&with[]=images&approved=0&orderByAsc=created_at";

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          self.approves = response.data.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },

    makeFormData: function () {
      const fd = new FormData();

      let photo = document.getElementById("images"); // Pega as imagens do input atualizado
      for (let i = 0; i < photo.files.length; i++) {
        fd.append("photo_" + i, photo.files[i]);
      }

      fd.append("photo_length", photo.files.length); // Quantidade de fotos que estão sendo carregadas
      fd.append("user_id", this.$store.state.user.id);
      fd.append("text", this.text);
      fd.append("approved", 0);

      fd.append("_method", "POST");

      return fd;
    },

    newPost: function () {
      const self = this;
      const api = self.$store.state.api + "posts";

      let fd = self.makeFormData();

      self.$loading(true);

      axios
        .post(api, fd)
        .then(() => {

          self.text = "";
          self.previewImages = "";


          if (self.$refs.fileInput) {
            self.$refs.fileInput.value = "";
          }

          if (self.$store.state.user.mod) {
            self.getApproves();
          }

          self.getPosts();

          self.$message(
            null,
            "Post feito, por favor aguarde a aprovação de um moderador",
            "success"
          );
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },


    getComments: function (id, index, active) {
      const self = this;

      self.$loading(true);

      if (!active) {
        const api =
          self.$store.state.api +
          "comments?with[]=user&with[]=comment_likes&orderByDesc=created_at&post_id=" +
          id;

        self.comment[id] = "";

        axios
          .get(api)
          .then((response) => {
            self.posts[index].comments = response.data.data;

            self.posts[index].comments.forEach((comment) => {
              if (
                !comment.hasOwnProperty("likes_count") ||
                typeof comment.likes_count !== "number"
              ) {
                comment.likes_count = comment.comment_likes.length;
              }

              comment.liked = false;

              comment.comment_likes.forEach((like) => {
                if (like.user_id === self.$store.state.user.id) {
                  comment.liked = true;
                }
              });
            });

            self.posts[index].show_comments = true;

            self.$loading(false);
          })
          .catch((error) => {
            self.$loading(false);
            self.$message(null, error.response.data, "error");
          });
      } else {
        self.posts[index].show_comments = false;
        self.$loading(false);
      }
    },

    selectCommentLikes: function (comment) {
      this.selectedCommentLikes = comment.comment_likes;
    },
    selectPostLikes: function (post) {
      this.selectedPostLikes = post.likes;
    },

    addComment: function (id, index) {
      const self = this;
      const api = self.$store.state.api + "comments";

      self.$loading(true);

      axios
        .post(api, {
          text: self.comment[id],
          post_id: id,
          user_id: self.$store.state.user.id,
        })
        .then(() => {
          // Atualiza os comentários do post específico sem reposicionar a tela
          self.getComments(id, index);

          // Atualiza apenas a contagem no post
          self.posts[index].comments_count += 1;

          // Limpa o campo de comentário
          self.comment[id] = "";

          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },

    addEmoji(emoji, postId) {
      this.comment[postId] = this.comment[postId] + emoji.native;
      this.comment.push();
    },

    react: function (postId, index) {
      const self = this;
      let api = "";

      self.posts[index].liked = !self.posts[index].liked;

      if (self.posts[index].liked) {
        api = `${self.$store.state.api}likes/${postId}/like`;
      } else {
        api = `${self.$store.state.api}delete-like`;
      }

      axios
        .post(api, { post_id: postId, user_id: self.$store.state.user.id })
        .then((response) => {
          self.posts[index].likes_count = response.data.likes_count;
          // self.getPosts();
          self.getNotifications();
        })
        .catch((error) => {
          console.error("API error:", error);
          self.$message(null, error.response.data, "error");
        });
    },

    reactToComment: function (postId, commentId, index, commentIndex) {
      const self = this;
      let api = "";

      self.posts[index].comments[commentIndex].liked =
        !self.posts[index].comments[commentIndex].liked;

      if (self.posts[index].comments[commentIndex].liked) {
        self.posts[index].comments[commentIndex].likes_count++;
      } else {
        self.posts[index].comments[commentIndex].likes_count--;
      }

      if (self.posts[index].comments[commentIndex].liked) {
        api = `${self.$store.state.api}comment_likes/${commentId}/like`;
      } else {
        api = self.$store.state.api + "delete-comment-like";
      }
      axios
        .post(api, {
          comment_id: commentId,
          user_id: self.$store.state.user.id,
        })
        .then(() => {
          self.$forceUpdate();
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    setApprove: function (id, status) {
      const self = this;
      const api = self.$store.state.api + "approve/";

      axios
        .post(api, { id: id, approved: status })
        .then(() => {
          self.getApproves();
          self.getPosts();

          if (status === 1) {
            self.$message(null, "Post Aprovado com Sucesso!", "success");
          } else {
            self.$message(null, "Post Rejeitado com Sucesso!", "error");
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    toggleEmojiPicker(postId) {
      // Inverte a visibilidade para o post específico
      this.$set(
        this.emojiPickerVisible,
        postId,
        !this.emojiPickerVisible[postId]
      );
    },

    // deletePost: function(id)
    // {
    //   const self = this;
    //   const api = self.$store.state.api + "delete-post";

    //   axios
    //     .post(api, {id: id})
    //     .then(() => {
    //       self.getPosts();
    //     })
    //     .catch((error) => {
    //       self.$message(null, error.response.data, "error");
    //     });
    // },
    deletePost(id) {
      const self = this;

      swal({
        title: "Deseja deletar o registro?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "O registro não foi excluido!", "info");
            break;

          case "confirm":
            let api = self.$store.state.api + "delete-post";
            axios
              .post(api, { id: id })
              .then((response) => {
                swal("Sucesso", "Registro excluído com sucesso!", "success");
                self.getPosts();
              })
              .catch((error) => {
                swal(
                  "Erro",
                  "Este registro não pode ser deletado, pois já está sendo utilizado no sistema!",
                  "error"
                );
              });
            break;
        }
      });
    },

    deleteComment(commentId, postId, postIndex) {
      const self = this;

      swal({
        title: "Deseja deletar o comentário?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((confirmed) => {
        if (confirmed === "confirm") {
          swal({
            title: "Aguarde...",
            text: "Excluindo comentário...",
            icon: "info",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

          const api = self.$store.state.api + "comments/" + commentId;

          axios
            .delete(api)
            .then(() => {
              // Atualiza os comentários do post sem recarregar toda a tela
              self.getComments(postId, postIndex);

              // Atualiza a contagem de comentários manualmente
              self.posts[postIndex].comments_count -= 1;

              // Remove o comentário da lista local
              const commentIndex = self.posts[postIndex].comments.findIndex(
                (comment) => comment.id === commentId
              );
              if (commentIndex !== -1) {
                self.posts[postIndex].comments.splice(commentIndex, 1);
              }

              swal.close();
              swal("Sucesso", "Comentário apagado com sucesso", "success");
            })
            .catch((error) => {
              swal.close();
              swal("Erro", error.response.data, "error");
            });
        }
      });
    },
  },
  mounted() {
    const self = this;

    self.getPosts(); // Carregar posts existentes
    self.getApproves(); // Carregar aprovações
    self.getDailyDone(); // Carregar status diário

    // Adiciona listener para detectar rolagem
    window.addEventListener("scroll", this.handleScroll);

    // Escuta evento de criação de posts em tempo real
    window.Echo.channel("posts").listen("PostCreated", (e) => {
      const currentUserId = this.$store.state.user.id;
      if (e.post.user_id !== currentUserId) {
        console.log("Novo post criado:", e.post);
        this.posts.unshift(e.post);
        this.$message(null, "Novo post disponível!", "info");
        this.getPosts();
      }
    });
    window.Echo.channel("likes").listen("LikeCreated", (e) => {
      console.log("Evento recebido: ", e);
      const currentUserId = this.$store.state.user.id;

      if (e.like && e.like.post_id) {
        const postIndex = this.posts.findIndex(
          (post) => post.id === e.like.post_id
        );

        if (postIndex !== -1) {
          const post = this.posts[postIndex];

          if (e.like.user_id !== currentUserId) {
            post.likes_count += 1;
          }

          post.likes.push(e.like);
          this.$set(this.posts, postIndex, { ...post });

          if (this.selectedPost && this.selectedPost.id === e.like.post_id) {
            this.selectedPostLikes = [...post.likes];
            console.log(
              "Curtidas atualizadas no modal:",
              this.selectedPostLikes
            );
          }

          if (e.like.user_id !== currentUserId) {
            this.$message(null, "Nova curtida em seu post!", "info");
          }
        }
      } else {
        console.log("Notificação ignorada: o like não contém post_id.");
      }
    });
    window.Echo.channel("likes_deleted").listen("LikeDeleted", (e) => {
      console.log("Evento de exclusão de curtida recebido:", e);
      const postIndex = this.posts.findIndex((post) => post.id === e.postId);

      if (postIndex !== -1) {
        const post = this.posts[postIndex];

        // Verificar se likes_count é válido; caso contrário, inicializar como 0
        if (typeof post.likes_count !== "number") {
          post.likes_count = post.likes ? post.likes.length : 0;
        }

        const likeIndex = post.likes.findIndex(
          (like) => like.user_id === e.userId
        );

        if (likeIndex !== -1) {
          post.likes.splice(likeIndex, 1);
          post.likes_count = Math.max(0, post.likes_count - 1); // Garantir que não fique negativo
          this.$set(this.posts, postIndex, { ...post });

          if (this.selectedPost && this.selectedPost.id === e.postId) {
            this.selectedPostLikes = [...post.likes];
            console.log(
              "Curtidas atualizadas no modal após exclusão:",
              this.selectedPostLikes
            );
          }
        } else {
          console.log("Curtida não encontrada no post.");
        }
      } else {
        console.log(
          "Post não encontrado para atualizar após exclusão de curtida."
        );
      }
    });
  },

  beforeDestroy() {
    // Remove listener para evitar vazamentos de memória
    window.removeEventListener("scroll", this.handleScroll);
  },

  created() {},
};
</script>


<style scoped>


.btn-danger {
  background-color: rgb(255, 0, 0);
  color: white;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.btn-danger:hover {
  background-color: rgb(124, 1, 1);
}


.col-responsive {
  flex: 0 0 100%;
  max-width: 100%;
}

.carousel-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
}

.carousel-image img {
  width: 100%;
  max-width: 500px;
  height: 100%; 
  max-height: 500px; 
  aspect-ratio: 2=1 / 1; 
  object-fit: cover; 
  border-radius: 8px;
  border: solid #15488a 1px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
}

.carousel-btn:hover {
  background: rgba(0, 0, 0, 0.8);
}

.prev-btn {
  left: 20px;
}

.next-btn {
  right: 20px;
}

.btn-scroll-up {
  position: fixed;
  top: 90px; /* Distância do rodapé */
  left: 58%; /* Centralizado horizontalmente */
  transform: translateX(-50%); /* Ajusta para o centro exato */
  width: 50px;
  height: 50px;
  background-color: #15488a;
  color: white;
  border: none;
  border-radius: 50%; /* Forma circular */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para destaque */
  opacity: 0.8;
  z-index: 1000; /* Garantir que fique acima de outros elementos */
  transition: opacity 0.3s ease;
}

.btn-scroll-up:hover {
  opacity: 1;
  background-color: #2c3e50; /* Cor escura no hover */
}

/* Avatar do usuário */
.post-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

/* Nome do usuário */
.post-user-name {
  font-size: 2.4vh;
  font-weight: bold;
  margin-left: 10px;
  line-height: 1.8vh;
}

/* Data do post */
.post-user-date {
  font-size: 1.5vh;
  color: #6c757d;
}

.btn-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hr-curtidas {
  width: 100%;
}

.row {
  padding: 10px 0 10px 0; /* Remove o padding padrão */
}

.user-img img {
  width: 50%;
  height: auto;
  max-height: 300px;
  border-radius: 8px;
  margin: 10px auto;
  display: block;
  object-fit: contain;
}

/* Estilo do Desktop */
.custom-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 255, 128, 0);
  backdrop-filter: blur(3px);
  opacity: 0.98;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.custom-loading-spinner {
  width: 100px;
  height: 100px;
  z-index: 10000;
}

/* Container do CKEditor */
.ck-editor-container {
  background-color: #1e1e2f; /* Fundo azul do container */
  border-radius: 10px; /* Cantos arredondados */
  padding: 15px; /* Espaçamento interno */
  border: 1px solid #ccc; /* Borda ao redor do container */
  box-sizing: border-box;
}

/* Título do Post */
.ck-editor-container h3 {
  margin: 0 0 10px 0; /* Espaço abaixo do título */
  color: #aaa; /* Cor do texto do título */
  font-size: 18px;
  font-weight: bold;
}

/* CKEditor Estilo Interno */
.ck-editor__editable {
  min-height: 300px; /* Altura mínima */
  color: black; /* Texto padrão preto durante edição */
  background-color: white; /* Fundo branco */
  padding: 15px; /* Espaçamento interno */
  border-radius: 8px; /* Cantos arredondados */
  border: 1px solid #ccc; /* Borda leve ao redor */
  box-sizing: border-box; /* Inclui padding dentro da largura */
}

/* Títulos dentro do CKEditor - Apenas enquanto estiverem no editor */
.ck-editor__editable h1,
.ck-editor__editable h2,
.ck-editor__editable h3 {
  font-family: inherit !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
  color: black !important;
}

/* Link Hover */
.like-link:hover b {
  color: #4488aa;
}

.col-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  box-sizing: border-box;
}

.btn-crud {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.modal-personalizada {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 15px;
  padding-right: 15px;
}

/* Estilos Específicos para Mobile */
@media (max-width: 768px) {
  .col-responsive {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .carousel-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
}

.carousel-image img {
  width: 100%;
  max-width: 500px;
  height: 100%; 
  max-height: 500px; 
  aspect-ratio: 2=1 / 1; 
  object-fit: cover; 
  border-radius: 8px;
  border: solid #15488a 1px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
}

.carousel-btn:hover {
  background: rgba(0, 0, 0, 0.8);
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

  .btn-scroll-up {
    position: fixed;
    top: 90px; /* Distância do rodapé */
    left: 50%; /* Centralizado horizontalmente */
    transform: translateX(-50%); /* Ajusta para o centro exato */
    width: 50px;
    height: 50px;
    background-color: #15488a; /* Cor azul */
    color: white;
    border: none;
    border-radius: 50%; /* Forma circular */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para destaque */
    opacity: 0.8;
    z-index: 1000; /* Garantir que fique acima de outros elementos */
    transition: opacity 0.3s ease;
  }

  .btn-scroll-up:hover {
    opacity: 1;
    background-color: #2c3e50; /* Cor escura no hover */
  }

  /* Avatar do usuário */
  .post-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  /* Nome do usuário */
  .post-user-name {
    font-size: 1.6vh;
    font-weight: bold;
    margin-left: 10px;
    line-height: 1.8vh;
  }

  /* Data do post */
  .post-user-date {
    font-size: 1.2vh;
    color: #6c757d;
  }

  .hr-curtidas {
    width: 100%;
  }

  .row {
    padding: 10px 25px; /* Adiciona o padding apenas no celular */
  }

  .user-img img {
    width: 90%; /* Ajusta para ocupar 90% em telas menores */
    max-height: 200px; /* Define uma altura máxima menor */
  }
  .ck-editor-container {
    padding: 10px; /* Reduz espaçamento interno */
    border-radius: 8px; /* Suaviza os cantos */
  }

  .ck-editor__editable {
    padding: 12px; /* Espaçamento interno para mobile */
    min-height: 150px; /* Altura mínima reduzida */
    font-size: 14px; /* Ajusta tamanho do texto */
    border-radius: 6px; /* Cantos mais suaves */
  }

  .ck-editor-container h3 {
    font-size: 16px; /* Tamanho menor no título */
    margin-bottom: 8px; /* Reduz o espaçamento inferior */
  }
  .btn-crud {
    position: relative;
  }
}

@media (max-width: 390px) {
  .user-img img {
    width: 95%; /* Ocupa quase toda a tela em dispositivos muito pequenos */
    max-height: 150px; /* Define um limite de altura menor ainda */
  }
  .custom-loading-overlay {
    backdrop-filter: blur(8px); /* Aumenta o desfoque no mobile */
    opacity: 1;
    flex-direction: column;
    padding-top: 20px; /* Espaçamento do topo */
  }

  .custom-loading-spinner {
    width: 60px; /* Reduz o tamanho do spinner para mobile */
    height: 60px;
    margin-bottom: 20px; /* Espaçamento abaixo do spinner */
  }

  .ck-editor__editable {
    padding: 10px; /* Espaçamento interno reduzido */
    border-radius: 6px; /* Cantos mais suaves */
  }
}
</style>