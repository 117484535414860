import Crud from '../views/StatusCall/Crud'
import Entity from '../views/StatusCall/Add'


let routes = {
    path: '/statusCall',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/statusCall/add',
            name: 'NewStatusCall',
            component: Entity,
            meta: {
                pageTitle: 'Status de Chamado',
                breadcrumb: [
                    {
                        text: 'Status de Chamado',
                        active: false,
                        to: '/statusCall',
                    },
                    {
                        text: 'Adicionar status de chamado',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/statusCall/edit/:id?',
            name: 'EditStatusCall',
            component: Entity,
            meta: {
                pageTitle: 'Status de Chamado',
                breadcrumb: [
                    {
                        text: 'Status de Chamado',
                        active: false,
                        to: '/statusCall',
                    },
                    {
                        text: 'Editar Status de Chamado',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/statusCall',
            name: 'list-status-call',
            component: Crud,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Status de Chamado',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
