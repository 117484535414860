export default [
  {
    title: 'Home',
    route: 'Posts',
    icon: 'MapIcon',
    perfil_id: [1, 7, 8],
  },
  {
    title: 'Fechamento Cards',
    route: 'list-graphics',
    icon: 'ActivityIcon',
    perfil_id: [8],
  },
  {
    title: 'Fechamento Chamados',
    route: 'list-graphic-tickets',
    icon: 'PieChartIcon',
    perfil_id: [8],
  },
  {
    title: 'Reembolsos',
    route: 'list-reimbursement-solicitations',
    icon: 'DollarSignIcon',
    perfil_id: [1, 7, 8],
  },
  {
    title: 'Dasp',
    route: 'list-dasps',
    icon: 'FileIcon',
    perfil_id: [1, 7, 8],
  },
  {
    title: 'Chamados',
    icon: 'MailIcon',
    route: 'list-calls',
    perfil_id: [1, 7, 8, 10],
    children: [
      {
        title: 'Todos Chamados',
        route: 'list-calls',
        perfil_id: [1, 7, 8, 10],
      },
      {
        title: 'Finalizados e Cancelados',
        route: 'list-calls-finish-cancels',
        perfil_id: [1, 7, 8, 10],
      },
      {
        title: 'Meus Chamados',
        route: 'list-my-calls',
        perfil_id: [1, 7, 8, 10],
      },
    ]
  },
  {
    title: 'Tarefas',
    icon: 'ToolIcon',
    route: 'list-tasks',
    perfil_id: [1, 7, 8, 11],
  },
  {
    title: 'Daily',
    route: 'list-dailies',
    icon: 'GitPullRequestIcon',
    perfil_id: [1, 7, 8],
    isDaily: true,
  },
  {
    title: 'Escalas',
    route: 'list-home_offices',
    icon: 'CommandIcon',
    perfil_id: [1, 7, 8],
  },
  {
    title: 'Calendário',
    route: 'calendario',
    icon: 'CalendarIcon',
    perfil_id: [1, 7, 8],
  },
  {
    title: 'Cadastros',
    icon: 'PlusSquareIcon',
    perfil_id: [1, 7, 8],
    children: [
      {
        title: 'Centro de Custos',
        route: 'list-bill_centers',
        perfil_id: [7, 8],
      },
      {
        title: 'Clientes',
        route: 'list-clients',
        perfil_id: [1, 7, 8],
      },
      {
        title: 'Contatos dos Clientes',
        route: 'list-client-contacts',
        perfil_id: [1, 7, 8],
      },
      {
        title: 'Grupos de Custos',
        route: 'list-groups',
        perfil_id: [7, 8],
      },
      {
        title: 'Equipes',
        route: 'list-teams',
        perfil_id: [7, 8],
      },
      {
        title: 'Motivos Delete',
        route: 'list-reasons-delete-calls',
        perfil_id: [7, 8],
      },
      {
        title: 'Tipos de sistema',
        route: 'list-project_types',
        perfil_id: [7, 8],
      },
      {
        title: 'Tipos de chamados',
        route: 'list-type-calls',
        perfil_id: [7, 8],
      },
      {
        title: 'Tipos de Reembolsos',
        route: 'list-reimbursement-types',
        perfil_id: [7, 8],
      },
      {
        title: 'Tipos de Notificação',
        route: 'list-notification-types',
        perfil_id: [1, 7, 8],
      },
      {
        title: 'Status dos Chamados',
        route: 'list-status-call',
        perfil_id: [1, 7, 8],
      },
      {
        title: 'Status dos Projetos',
        route: 'list-status-projects',
        perfil_id: [7, 8],
      },
      {
        title: 'Usuários',
        route: 'list-users',
        perfil_id: [1, 7, 8],
      },
    ]
  },
  {
    title: 'Sistemas',
    route: 'list-projects',
    icon: 'CloudIcon',
    perfil_id: [1, 7, 8],
  },
  {
    title: 'Pacotes de Versões',
    route: 'list-versions',
    icon: 'HeartIcon',
    perfil_id: [1, 7, 8],
  },
  {
    title: 'PDF das Versões',
    route: 'list-versions-package',
    icon: 'CopyIcon',
    perfil_id: [1, 7, 8],
  },
  {
    title: 'Base de Conhecimentos',
    route: 'list-knowledge-bases',
    icon: 'BookOpenIcon',
    perfil_id: [1, 7, 8],
  },
  {
    title: 'Dashboard',
    route: 'dashboard-tv',
    icon: 'TvIcon',
    perfil_id: [1, 7, 8],
  },
]
